import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GetTenantDashboard, GetTenantMainLayout, GetTenantNotLoggedInLayout } from './tenant.helper';
import { DealerGroupDetailsViewComponent } from './views/dealer-group-details-view/dealer-group-details-view.component';
import { ClientListViewComponent } from './views/client-list-view/client-list-view.component';
import { AdviserDetailsViewComponent } from './views/adviser-details-view/adviser-details-view.component';
import { AdviserDetailsEditViewComponent } from './views/adviser-details-edit-view/adviser-details-edit-view.component';
import { ClientViewComponent } from './views/client-view/client-view.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AdviserListViewComponent } from './views/adviser-list-view/adviser-list-view.component';
import { FeatureToggleGuardService } from './route-guard/feature-toggle-guard.service';
import { FeatureToggleName } from './services/featureToggle.service';
import { AdviserGroupStatementsViewComponent } from './views/group-statements/group-statements-view.component';
import { LoginErrorPageComponent } from './views/login-error/login-error.component';
import { MsalCheckService } from './route-guard/msal-check.service';
import { ErrorPageGuardService } from './route-guard/error-page-guard.service';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { AccountDataGuardService } from './route-guard/account-data-guard.service';


var routes: Routes = [
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: 'error',
    component: GetTenantNotLoggedInLayout.layout(),
    canActivate: [ErrorPageGuardService],
    children: [
      {
        outlet: 'master',
        path: '',
        component: ErrorPageComponent
      }
    ]
  },
  {
    path: 'account-error',
    canActivate: [MsalCheckService],
    component: GetTenantNotLoggedInLayout.layout(),
    children: [
      {
        outlet: 'master',
        path: '',
        component: LoginErrorPageComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        canActivate: [AccountDataGuardService],
        children: [
          {
            path: 'dashboard',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [MsalGuard],
            children: [
              {
                outlet: 'master',
                path: '',
                component: GetTenantDashboard.layout()
              }
            ]
          },
          {
            path: 'dealer-group-details',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [],
            children: [
              {
                outlet: 'master',
                path: '',
                component: DealerGroupDetailsViewComponent
              }
            ]
          },
          {
            path: 'adviser-list',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [FeatureToggleGuardService],
            data: { flag: [FeatureToggleName.canViewAdviserList] },
            children: [
              {
                outlet: 'master',
                path: '',
                component: AdviserListViewComponent
              }
            ]
          },
          {
            path: 'group-documents',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [FeatureToggleGuardService],
            data: { flag: [FeatureToggleName.canViewDocuments] },
            children: [
              {
                outlet: 'master',
                path: '',
                component: AdviserGroupStatementsViewComponent
              }
            ]
          },
          {
            path: 'client-list',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [FeatureToggleGuardService],
            data: { flag: [FeatureToggleName.canViewClientList] },
            children: [
              {
                outlet: 'master',
                path: '',
                component: ClientListViewComponent
              }
            ]
          },
          {
            path: 'client-view/:id',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [],
            children: [
              {
                outlet: 'master',
                path: '',
                component: ClientViewComponent
              }
            ]
          },

          {
            path: 'adviser-details',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [FeatureToggleGuardService],
            data: { flag: [FeatureToggleName.canEditAdviserDetails] },
            children: [
              {
                outlet: 'master',
                path: '',
                component: AdviserDetailsViewComponent
              }
            ]
          },
          {
            path: 'adviser-details-edit',
            component: GetTenantMainLayout.mainLayout(),
            canActivate: [],
            children: [
              {
                outlet: 'master',
                path: '',
                component: AdviserDetailsEditViewComponent
              }
            ]
          },
          {
            path: '**',
            redirectTo: '/dashboard'
          }]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
