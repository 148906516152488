import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { adviserListingView_Form, adviserListingView_List, adviserListingView_SearchFormatted } from './selectors';
import { NextPageAction, RequestAction, SetAsAtDateAction } from './actions';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { AdviserSearchModel } from 'src/app/model/adviser-search.model';
import { AdviserListingSearchModel } from '../adviser-list-widget/state';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-adviser-list',
  templateUrl: './adviser-list.component.html',
  styleUrls: ['./adviser-list.component.scss']
})
export class AdviserListComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(adviserListingView_List));
  form$ = this.store.pipe(select(adviserListingView_Form));
  search$ = this.store.pipe(select(adviserListingView_SearchFormatted));
  query: AdviserListingSearchModel | undefined = undefined;
  helper = new Helper();
  constructor(
    public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.onSelectedChanged(this.form$, async (x) => {
      var filter = await this.formatQuery(x.value);
      if (this.isQueryDifferent(x.value) && x.value.currentPage >= 0) {
        this.store.dispatch(RequestAction({ search: filter, page: 0 }))
      }
    }, 400)
  }


  async onLoadMore() {
    var form = await this.helper.getValue(this.form$);
    var search = await this.helper.getValue(this.search$);
    this.store.dispatch(NextPageAction());
    this.store.dispatch(RequestAction({ search: search, page: form.value.currentPage + 1 }));
  }


  isQueryDifferent(filter: AdviserListingSearchModel): boolean {
    if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
      this.query = filter;
      return true;
    }
    return false;
  }
  asAtTodayClick() {
    var today = new Date();
    this.store.dispatch(SetAsAtDateAction({ payload: today.toISOString() }));
  }

  async formatQuery(value: AdviserListingSearchModel): Promise<string> {
    var list = [];
    if (value.asAt) {
      list.push("asAt:" + value.asAt);
    }
    if (value.q) {
      list.push("search:" + value.q);
    }
    return list.join(';');
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onAdviserViewClick(data: AdviserSearchModel) {
    this.router.navigate(['adviser-view', data.id]);
  }
}

