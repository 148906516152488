import { AccountModel } from './../../../../model/account.model';
import { mainLayout_Animated, mainLayout_Expanded } from './selectors';
import { MenuModel } from './../../../../model/menu.model';
import { AppState } from 'src/app/store/app.states';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import {
  commomState_IsLoading, commomState_LoggedinInfo, commomState_MenuList,
} from 'src/app/store/common/common.selectors';
import { MainExpandedRequestAction, MainSetAnimatedPageAction } from './actions';
import { SMRTState } from '../../store/smrt.states';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';
import { SetMenuAction } from 'src/app/store/common/common.actions';
import { MsalService } from '@azure/msal-angular';
import { FeatureToggleName } from 'src/app/services/featureToggle.service';
import { AdvisorPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  expanded: MenuModel[] = [];
  animated$ = this.store.pipe(select(mainLayout_Animated));
  expanded$ = this.smrtstore.pipe(select(mainLayout_Expanded));
  menuList$ = this.store.pipe(select(commomState_MenuList));
  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  accountFeatureToggles?: AccountFeatureToggle[] = undefined;
  featureToggleValues: any = null;
  featureToggleHelper = new FeatureToggleHelper();

  menuMode = "side";
  showMenuIconTop = true;
  selectedAccount?: AccountModel = undefined;
  selected: string = null;

  @ViewChild('templateBottomSheet') TemplateBottomSheet?: TemplateRef<any>;
  menu = [
    { id: '1', name: 'Overview', link: '/dashboard', icon: 'house', children: [] } as MenuModel,

    {
      id: '2', name: 'Client list', link: '/client-list', icon: 'people', children: [], featureFlag: FeatureToggleName.canViewClientList
    } as MenuModel,
    {
      id: '6', name: 'Adviser list', link: '/adviser-list', icon: 'people', children: [], featureFlag: FeatureToggleName.canViewAdviserList
    } as MenuModel,
    // {
    //   id: '3', name: 'Dealer Group Details', link: '/dealer-group-details', icon: 'grid_view', children: []
    // } as MenuModel,
    {
      id: '4', name: 'Documents', link: '/group-documents', icon: 'file_present', children: [], featureFlag: FeatureToggleName.canViewDocuments
    } as MenuModel,
    {
      id: '5', name: 'Profile', icon: 'person', children: [
        // {
        //   id: '5-1', name: 'Edit profile', link: '/dashboard1', icon: 'edit', children: []
        // } as MenuModel,
        {
          id: '5-2', name: 'Sign out', action: 'signout', icon: 'logout', children: []
        } as MenuModel,
      ]
    } as MenuModel,

  ]

  @ViewChild("drawer") drawer?: MatDrawer = undefined;

  constructor(public store: Store<AppState>,
    public smrtstore: Store<SMRTState>,
    public dialog: MatDialog,
    public appstore: Store<AdvisorPortalSharedState>,
    private titleService: Title,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private msalInstance: MsalService
  ) {
    super();
    this.titleService.setTitle("smartMonday Adviser Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.sessionStorageService.storageChanges.subscribe(x => {
      var value = x['selected'];

      if (value) {
        this.selected = value;
      }
    })

    this.featureToggleValues = {
      available: FeatureToggleValue.available,
      hidden: FeatureToggleValue.hidden,
      disabled: FeatureToggleValue.disabled
    };

    setTimeout(() => {
      this.store.dispatch(MainSetAnimatedPageAction());
    }, 1000);


    this.sub = this.expanded$.subscribe(x => {
      this.expanded = x;
    });

    this.setMenu(this.menu);
  }
  bs: any = null;

  openMenu() {
    if (this.bs) {
      this.bs.dismiss();
      this.bs = null;
      return;
    }
    this.bs = this.bottomSheet.open(this.TemplateBottomSheet, {
      panelClass: 'menu-items'
    });
  }


  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onItemSelectedClick(item: MenuModel) {
    this.store.dispatch(MainExpandedRequestAction({ menuItem: item }))

    if (item.link?.startsWith('http')) {
      window.open(item.link, "_blank");
    }
    else {
      if (item.link)
        this.router.navigate([item.link]);
      if (item.action) {
        switch (item.action) {
          case 'signout':
            this.onSignoutClick();
            break;
        }
      }
    }
  }

  isMenuExpanded(item: MenuModel) {
    return this.expanded.filter(x => x.id == item.id).length > 0;
  }

  onSignoutClick(): void {
    this.msalInstance.logoutRedirect(
      { postLogoutRedirectUri: environment.b2cLogoutUrl }
    );
    sessionStorage.clear();
  }


  setMenu(menu: MenuModel[]) {
    this.store.dispatch(SetMenuAction({ data: menu }))
  }

}
