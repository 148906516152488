import { environment } from './../environments/environment';
import { Type } from '@angular/core';
import { TenantDashboardComponent as SMRTTenantDashboardComponent } from './modules/smrt/views/dashboard/dashboard.component'
import { MainMasterComponent as SMRTMainMasterComponent } from './modules/smrt/layout/main/main.master'
import { SMRTModule } from './modules/smrt/smrt.module';
import { NotLoggedInMasterComponent as SMRTNotLoggedInMasterComponent } from './modules/smrt/layout/not-loggedin/not-loggedin.master';


export class GetTenantModule {
  static getModule(list: any[]) {

    if (environment.code == 'smrt')
      list.push(SMRTModule);
  }
}

export class GetTenantNotLoggedInLayout {
  static layout(): Type<any> {

    if (environment.code == 'smrt')
      return SMRTNotLoggedInMasterComponent;


    return null;
  }

}

export class GetTenantDashboard {
  static layout(): any {

    if (environment.code == 'smrt')
      return SMRTTenantDashboardComponent;

    return null;
  }

}

export class GetTenantMainLayout {
  static mainLayout(): any {

    if (environment.code == 'smrt')
      return SMRTMainMasterComponent;

    return null;
  }

}

export function GetMainLayout(): any {

  if (environment.code == 'smrt')
    return SMRTMainMasterComponent;

  return null;

}

