import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    private storageSubject = new BehaviorSubject<any>(this.getSessionStorageData());

    constructor() {
        // Listen for changes in sessionStorage
        window.addEventListener('storage', this.onStorageChange.bind(this));
    }

    // Set data in sessionStorage and notify subscribers
    setItem(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
        this.storageSubject.next(this.getSessionStorageData());
    }

    // Get data from sessionStorage
    getItem(key: string): any {
        var value = this.tryParse(sessionStorage.getItem(key) || 'null')
        return value;
    }

    // Get the entire sessionStorage data
    private getSessionStorageData(): any {
        let data: any = {};
        Object.keys(sessionStorage).forEach(key => {
            var value = this.tryParse(sessionStorage.getItem(key) || 'null')
            data[key] = value;
        });
        return data;
    }

    // Listen to storage events
    private onStorageChange(event: StorageEvent): void {
        if (event.storageArea === sessionStorage) {
            this.storageSubject.next(this.getSessionStorageData());
        }
    }

    // Subscribe to sessionStorage changes
    get storageChanges() {
        return this.storageSubject.asObservable();
    }

    tryParse(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return str;
        }
    }
}