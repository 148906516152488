import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { AdvisorPortalSharedState } from './shared.states';
import { memberListingViewReducer } from '../components/members-list/reducer';
import { dealerGroupDetailsReducer } from '../components/dealer-group-details/reducer';
import { memberListingWidgetViewReducer } from '../components/members-list-widget/reducer';
import { adviserDetailsReducer } from '../components/adviser-details/reducer';
import { adviserDetailsEditReducer } from '../components/adviser-details-edit/reducer';
import { memberViewReducer } from '../components/members-view/reducer';
import { cashTransactionListReducer } from '../components/members-view/cash-transactions/reducer';
import { beneficiariesReducer } from '../components/members-view/beneficiaries/reducer';
import { rolloverReducer } from '../components/members-view/rollovers/reducer';
import { interimStatementReducer } from '../components/members-view/interim-statement/reducer';
import { contributionsReducer } from '../components/members-view/contributions/reducer';
import { switchTransactionListReducer } from '../components/members-view/switch-transactions/reducer';
import { memberInsuranceReducer } from '../components/members-view/insurance/reducer';
import { accountComponentReducer } from '../components/members-view/account-components/reducer';
import { employerHistoryViewReducer } from '../components/members-view/employment-history/reducer';
import { memberCorrespondenceListReducer } from '../components/members-view/member-correspondence/reducer';
import { memberPensionReducer } from '../components/members-view/pension/reducer';
import { valuationViewReducer } from '../components/members-view/valuation/reducer';
import { widgetActiveMemberReducer } from '../components/members-active/reducer';
import { widgetNewMemberReducer } from '../components/members-new/reducer';
import { widgetExpiringMemberReducer } from '../components/members-expiring/reducer';
import { widgetTotalBalanceReducer } from '../components/members-total-balance/reducer';
import { membersOverviewViewReducer } from '../components/members-chart/reducer';
import { adviserListingWidgetViewReducer } from '../components/adviser-list-widget/reducer';
import { adviserListingViewReducer } from '../components/adviser-list/reducer';
import { adviserAuthorityReducer } from '../components/members-view/adviser-authority/reducer';
import { documentViewerListReducer } from '../components/document-viewer/reducer';

export const featureKey = 'member-portal-shared';

export const reducers = new InjectionToken<ActionReducerMap<AdvisorPortalSharedState>>(featureKey, {
  factory: () => ({
    memberListingViewState: memberListingViewReducer,
    memberViewState: memberViewReducer,
    memberListingWidgetViewState: memberListingWidgetViewReducer,
    dealerGroupDetailsState: dealerGroupDetailsReducer,
    adviserDetailsState: adviserDetailsReducer,
    adviserDetailsEditState: adviserDetailsEditReducer,
    cashTransactionListState: cashTransactionListReducer,
    beneficiariesState: beneficiariesReducer,
    rolloverState: rolloverReducer,
    interimStatementState: interimStatementReducer,
    contributionsState: contributionsReducer,
    switchTransactionListState: switchTransactionListReducer,
    memberInsuranceState: memberInsuranceReducer,
    accountComponentState: accountComponentReducer,
    employerHistoryViewState: employerHistoryViewReducer,
    memberCorrespondenceListState: memberCorrespondenceListReducer,
    memberPensionState: memberPensionReducer,
    valuationViewState: valuationViewReducer,
    widgetActiveMemberState: widgetActiveMemberReducer,
    widgetNewMemberState: widgetNewMemberReducer,
    widgetExpiringMemberState: widgetExpiringMemberReducer,
    widgetTotalBalanceState: widgetTotalBalanceReducer,
    membersOverviewViewState: membersOverviewViewReducer,
    adviserListingWidgetViewState: adviserListingWidgetViewReducer,
    adviserListingViewState: adviserListingViewReducer,
    adviserAuthorityState: adviserAuthorityReducer,
    documentViewerListState: documentViewerListReducer

  })
});
