import { environment } from 'src/environments/environment';
import { SystemConfigModel } from './../../model/system-config.model';
import * as Actions from './common.actions';
import { ICommonState } from './common.state';
import { Action, createReducer, on } from '@ngrx/store';

export const commonState: ICommonState = {
  isLoading: false,
  systemConfig: {} as SystemConfigModel,
  loggedInModel: null,
  tenantCode: environment.code,
  menuList: [],
  screenWidth: window.innerWidth,
  siteError: false
};

const reducerList = createReducer(commonState,

  on(Actions.IsLoadingAction, (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    };
  }),
  on(Actions.ChangeScreenWidthAction, (state, { payload }) => {
    return {
      ...state,
      screenWidth: payload,
    };
  }),
  on(Actions.SetMenuAction, (state, { data }) => {
    return {
      ...state,
      menuList: data,
    };
  }),
  on(Actions.DisplayCommunicationLinkingMemberAction, (state, { payload }) => {
    return {
      ...state,
      displayCommunicationLinkingMember: payload,
    };
  }),
  on(Actions.SystemConfigurationResponseAction, (state, { payload }) => {
    return {
      ...state,
      systemConfig: payload,
    };
  }),
  on(Actions.IsLoggedInResponseAction, (state, { data }) => {
    return {
      ...state,
      loggedInModel: data
    };
  }),
  on(Actions.SetSiteError, (state, { error }) => {
    return {
      ...state,
      siteError: error
    }
  }),
);

export function commonReducer(state: any | undefined, action: Action) {
  return reducerList(state, action);
}
