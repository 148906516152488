import { InputScrollDirective, InputAddressScrollDirective, InputDateScrollDirective } from './directives/input-scroll.directive';
import { ScreenWidthDirective } from './directives/screen-width.directive';
import { MessagePipe } from './pipes/message';
import { AppRadioDirective } from './directives/app-radio.directive';
import { AppButtonDirective } from './directives/appbutton.directive';
import { MaterialModule } from './../../app.material.module';
import { AppComponentWrapperComponent } from './components/app-component-wrapper/app-component-wrapper.component';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TenantConfigPipe } from './pipes/tenantconfig';
import { AnimateCssDirective } from './directives/animatecss.directive';
import { FadeInConcatDirective } from './directives/fadein-concat.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { MessageDirective } from './directives/message.directive';
import { PermissionDirective } from './directives/permission.directive';
import { NgrxFormsModule } from 'ngrx-forms';
import { featureKey, reducers } from './store/shared.reducers';
import { MemberPortalSharedRoutingModule } from './shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ConvertNanPipe } from './pipes/convertNan';
import { FeatureTogglePipe } from './pipes/featureToggle';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { UserTopDropdownComponent } from './components/user-top-dropdown/user-top-dropdown.component';
import { MembersActiveComponent } from './components/members-active/members-active.component';
import { MembersNewComponent } from './components/members-new/members-new.component';
import { MembersExpiringComponent } from './components/members-expiring/members-expiring.component';
import { MembersListComponent } from './components/members-list/members-list.component';
import { MemberListingViewEffects } from './components/members-list/effects';
import { NoDataComponent } from './components/no-data/no-data.component';
import { MembersChartComponent } from './components/members-chart/members-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountUpDirective } from './directives/count-up.directive';
import { MembersTotalBalanceComponent } from './components/members-total-balance/members-total-balance.component';

import { DealerGroupDetailsComponent } from './components/dealer-group-details/dealer-group-details.component';
import { MembersListWidgetComponent } from './components/members-list-widget/members-list-widget.component';
import { MemberListingWidgetViewEffects } from './components/members-list-widget/effects';
import { AppSearchFilterComponent } from './components/app-search-filter/app-search-filter.component';
import { AdviserDetailsWidgetComponent } from './components/adviser-details-widget/adviser-details-widget.component';
import { AdviserDetailsEffects } from './components/adviser-details/effects';
import { AdviserDetailsComponent } from './components/adviser-details/adviser-details.component';
import { AdviserDetailsEditComponent } from './components/adviser-details-edit/adviser-details-edit.component';
import { AdviserDetailsEditEffects } from './components/adviser-details-edit/effects';
import { AdviserDetailsHeaderComponent } from './components/adviser-details-header/adviser-details-header.component';
import { MemberViewComponent } from './components/members-view/members-view.component';
import { MemberViewEffects } from './components/members-view/effects';
import { CashTransactionsViewComponent } from './components/members-view/cash-transactions/cash-transactions-view.component';
import { CashTransactionsEffects } from './components/members-view/cash-transactions/effects';
import { BeneficiariesViewComponent } from './components/members-view/beneficiaries/beneficiaries-view.component';
import { BeneficiariesEffects } from './components/members-view/beneficiaries/effects';
import { RolloversViewComponent } from './components/members-view/rollovers/rollovers-view.component';
import { RolloversEffects } from './components/members-view/rollovers/effects';
import { InterimStatementEffects } from './components/members-view/interim-statement/effects';
import { InterimStatementViewComponent } from './components/members-view/interim-statement/interim-statement-view.component';
import { ContributionsViewComponent } from './components/members-view/contributions/contributions-view.component';
import { ContributionsEffects } from './components/members-view/contributions/effects';
import { SwitchTransactionsViewComponent } from './components/members-view/switch-transactions/switch-transactions-view.component';
import { SwitchTransactionsEffects } from './components/members-view/switch-transactions/effects';
import { MemberInsuranceComponent } from './components/members-view/insurance/member-insurance.component';
import { MemberInsuranceEffects } from './components/members-view/insurance/effects';
import { AccountComponentsViewComponent } from './components/members-view/account-components/account-components-view.component';
import { AccountComponentEffects } from './components/members-view/account-components/effects';
import { EmployerHistoryViewEffects } from './components/members-view/employment-history/effects';
import { EmploymentHistoryViewComponent } from './components/members-view/employment-history/employment-history-view.component';
import { MemberCorrespondenceViewComponent } from './components/members-view/member-correspondence/member-correspondence-view.component';
import { MemberCorrespondenceEffects } from './components/members-view/member-correspondence/effects';
import { MemberPensionViewComponent } from './components/members-view/pension/pension-view.component';
import { MemberPensionEffects } from './components/members-view/pension/effects';
import { YesNoPipe } from './pipes/yes-no-pipe';
import { SVGElderlyComponent } from './svg/svg-elderly/svg-elderly.view';
import { SVGPersonalDetailComponent } from './svg/svg-personal-detail/svg-personal-detail.view';
import { ValuationViewComponent } from './components/members-view/valuation/valuation.component';
import { ValuationViewEffects } from './components/members-view/valuation/effects';
import { ContentLoaderComponent } from './components/content-loader/content-loader.component';
import { SelectorComponent } from './components/selector/selector.component';
import { WidgetActiveMemberEffects } from './components/members-active/effects';
import { WidgetNewMemberEffects } from './components/members-new/effects';
import { WidgetExpiringMemberEffects } from './components/members-expiring/effects';
import { WidgetTotalBalanceEffects } from './components/members-total-balance/effects';
import { MemberOverviewViewEffects } from './components/members-chart/effects';
import { ExpiryDateComponent } from './components/expiry-date/expiry-date.component';
import { AdviserListingWidgetViewEffects } from './components/adviser-list-widget/effects';
import { AdviserListWidgetComponent } from './components/adviser-list-widget/adviser-list-widget.component';
import { AdviserListingViewEffects } from './components/adviser-list/effects';
import { AdviserListComponent } from './components/adviser-list/adviser-list.component';
import { AdviserAuthorityEffects } from './components/members-view/adviser-authority/effects';
import { AdviserAuthoritysViewComponent } from './components/members-view/adviser-authority/adviser-authority-view.component';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { DocumentViewerEffects } from './components/document-viewer/effects';
import { DocumentViewerViewComponent } from './components/document-viewer/document-viewer.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { PreviousFyContributionSummaryComponent } from './components/members-view/contributions/previous-fy-contribution-summary/previous-fy-contribution-summary.component';

@NgModule({
  declarations: [
    TenantConfigPipe,
    MessagePipe,
    FeatureTogglePipe,
    ConvertNanPipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    MessageDirective,
    ScreenWidthDirective,
    PermissionDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InputDateScrollDirective,
    AppComponentWrapperComponent,
    UserTopDropdownComponent,
    MembersActiveComponent,
    MembersNewComponent,
    MembersExpiringComponent,
    MembersListComponent,
    NoDataComponent,
    MembersChartComponent,
    CountUpDirective,
    MembersTotalBalanceComponent,
    AdviserDetailsWidgetComponent,
    MembersListWidgetComponent,
    DealerGroupDetailsComponent,
    AppSearchFilterComponent,
    AdviserDetailsComponent,
    AdviserDetailsEditComponent,
    AdviserDetailsHeaderComponent,
    MemberViewComponent,
    CashTransactionsViewComponent,
    BeneficiariesViewComponent,
    RolloversViewComponent,
    InterimStatementViewComponent,
    ContributionsViewComponent,
    SwitchTransactionsViewComponent,
    MemberInsuranceComponent,
    AccountComponentsViewComponent,
    EmploymentHistoryViewComponent,
    MemberCorrespondenceViewComponent,
    MemberPensionViewComponent,
    YesNoPipe,
    SVGElderlyComponent,
    SVGPersonalDetailComponent,
    ValuationViewComponent,
    ContentLoaderComponent,
    SelectorComponent,
    ExpiryDateComponent,
    AdviserListWidgetComponent,
    AdviserListComponent,
    AdviserAuthoritysViewComponent,
    DocumentViewerViewComponent,
    AdviserAuthoritysViewComponent,
    LoadMoreComponent,
    PreviousFyContributionSummaryComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    HttpClientModule,
    PdfJsViewerModule,
    NgApexchartsModule,
    IfaaUiComponentsModule,
    MemberPortalSharedRoutingModule,
    SharedDirectivesModule,
    MaterialModule,
    NgrxFormsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([
      MemberListingViewEffects,
      MemberListingWidgetViewEffects,
      AdviserDetailsEffects,
      AdviserDetailsEditEffects,
      MemberViewEffects,
      CashTransactionsEffects,
      BeneficiariesEffects,
      RolloversEffects,
      InterimStatementEffects,
      ContributionsEffects,
      SwitchTransactionsEffects,
      MemberInsuranceEffects,
      AccountComponentEffects,
      EmployerHistoryViewEffects,
      MemberCorrespondenceEffects,
      MemberPensionEffects,
      ValuationViewEffects,
      WidgetActiveMemberEffects,
      WidgetNewMemberEffects,
      WidgetExpiringMemberEffects,
      WidgetTotalBalanceEffects,
      MemberOverviewViewEffects,
      AdviserListingWidgetViewEffects,
      AdviserListingViewEffects,
      AdviserAuthorityEffects,
      DocumentViewerEffects
    ]),
    TranslateModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      animationDuration: 400,
      backgroundPadding: 0,
      animation: true
    })
  ],
  exports: [
    CountUpDirective,
    TenantConfigPipe,
    MessagePipe,
    FeatureTogglePipe,
    YesNoPipe,
    ConvertNanPipe,
    AnimateCssDirective,
    FadeInConcatDirective,
    FeatureToggleDirective,
    AppButtonDirective,
    AppRadioDirective,
    MessageDirective,
    PermissionDirective,
    ScreenWidthDirective,
    InputScrollDirective,
    InputAddressScrollDirective,
    InputDateScrollDirective,
    AppComponentWrapperComponent,
    UserTopDropdownComponent,
    MembersActiveComponent,
    MembersNewComponent,
    MembersExpiringComponent,
    MembersListComponent,
    MembersListComponent,
    NoDataComponent,
    MembersChartComponent,
    MembersTotalBalanceComponent,
    AdviserDetailsWidgetComponent,
    DealerGroupDetailsComponent,
    AppSearchFilterComponent,
    MembersListWidgetComponent,
    AdviserDetailsComponent,
    AdviserDetailsEditComponent,
    AdviserDetailsHeaderComponent,
    MemberViewComponent,
    CashTransactionsViewComponent,
    SVGElderlyComponent,
    ValuationViewComponent,
    ContentLoaderComponent,
    SelectorComponent,
    ExpiryDateComponent,
    AdviserListWidgetComponent,
    AdviserListComponent,
    AdviserAuthoritysViewComponent,
    DocumentViewerViewComponent,
    AdviserAuthoritysViewComponent,
    LoadMoreComponent,
    PreviousFyContributionSummaryComponent
  ],
  providers: [
    CurrencyPipe,
    YesNoPipe,
    FeatureTogglePipe
  ],
  entryComponents: [
  ]
})
export class AdviserPortalSharedModule { }
