<div id="dashboard-container">
  <div class="page-header">
    <app-toolbar-options icon="house">
      <div class="d-flex flex-column flex-fill" slot="start">

        <div class="d-flex flex-row justify-content-between">

          <div class="page-title">Adviser Portal</div>
        </div>
      </div>

    </app-toolbar-options>
  </div>
  <div class="page-content " *featuretoggle="canViewAdviserDashboard">
    <mat-progress-bar *ngIf="!selected" mode="indeterminate"></mat-progress-bar>
    <div class="d-flex flex-row">
      <div class="d-flex flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="d-flex flex-row">
            <div class="d-flex flex-fill adviser-details">
              <app-component-wrapper icon="manage_accounts" header="Welcome {{(loggedIn$|async)?.name}}"
                class="d-flex flex-fill p-3 adviser-details">

                <div class="d-flex flex-column h-100" slot="start">

                  <app-adviser-details-widget></app-adviser-details-widget>
                </div>
              </app-component-wrapper>
            </div>
            <div class="d-flex flex-wrap flex-row">
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-active></app-members-active>
                </div>
              </app-component-wrapper>
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-new></app-members-new>
                </div>
              </app-component-wrapper>
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-expiring></app-members-expiring>
                </div>
              </app-component-wrapper>

              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-total-balance></app-members-total-balance>
                </div>
              </app-component-wrapper>
            </div>
          </div>


        </div>
        <div class="d-flex flex-row flex-fill">
          <app-component-wrapper icon="insights" header="Members Overview" class="d-flex flex-fill p-3 mt-0">
            <div class="d-flex flex-column" slot="header">
            </div>
            <div class="d-flex flex-column" slot="start">
              <app-members-chart></app-members-chart>
            </div>
          </app-component-wrapper>
        </div>
      </div>
      <div class="d-flex member-list ">
        <app-component-wrapper icon="people" header="Client List" class="d-flex flex-fill p-3 member-list">

          <div class="d-flex flex-column" slot="header">
            <div class="d-flex flex-row align-items-center header-option" (click)="onClientListAdvancedClick()">
              Advanced view
              <mat-icon>arrow_forward</mat-icon>
            </div>

          </div>
          <div class="d-flex flex-column" slot="start">
            <app-members-list-widget></app-members-list-widget>

          </div>
        </app-component-wrapper>

      </div>
    </div>
  </div>

  <div class="page-content" *featuretoggle="canViewBrokerDashboard">
    <mat-progress-bar *ngIf="!selected" mode="indeterminate"></mat-progress-bar>
    <div class="d-flex flex-row">
      <div class="d-flex flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="d-flex flex-row">
            <div class="d-flex flex-fill adviser-details">
              <app-component-wrapper icon="manage_accounts" header="Welcome {{(loggedIn$|async)?.name}}"
                class="d-flex flex-fill p-3 adviser-details">

                <div class="d-flex flex-column h-100" slot="start">

                  <app-adviser-details-widget></app-adviser-details-widget>
                </div>
              </app-component-wrapper>
            </div>
            <!-- <div class="d-flex flex-wrap flex-row">
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-active></app-members-active>
                </div>
              </app-component-wrapper>
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-new></app-members-new>
                </div>
              </app-component-wrapper>
              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-expiring></app-members-expiring>
                </div>
              </app-component-wrapper>

              <app-component-wrapper icon="" header="" class="d-flex flex-fill p-3 member-widget">
                <div class="d-flex flex-column" slot="start">
                  <app-members-total-balance></app-members-total-balance>
                </div>
              </app-component-wrapper>
            </div> -->
          </div>


        </div>
        <!-- <div class="d-flex flex-row flex-fill">
          <app-component-wrapper icon="insights" header="Members Overview" class="d-flex flex-fill p-3 mt-0">
            <div class="d-flex flex-column" slot="header">
            </div>
            <div class="d-flex flex-column" slot="start">
              <app-members-chart></app-members-chart>
            </div>
          </app-component-wrapper>
        </div> -->
      </div>
      <div class="d-flex member-list ">
        <app-component-wrapper icon="people" header="Adviser List" class="d-flex flex-fill p-3 member-list">

          <div class="d-flex flex-column" slot="header">
            <div class="d-flex flex-row align-items-center header-option" (click)="onAdviserListAdvancedClick()">
              Advanced view
              <mat-icon>arrow_forward</mat-icon>
            </div>

          </div>
          <div class="d-flex flex-column" slot="start">
            <app-adviser-list-widget></app-adviser-list-widget>
          </div>
        </app-component-wrapper>

      </div>
    </div>

  </div>
</div>