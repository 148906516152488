import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { AdvisorPortalSharedState } from '../modules/shared/store/shared.states';
import { SessionStorageService } from './session-storage.service';

export var FeatureToggleName = {
  canViewBrokerDashboard: 'canViewBrokerDashboard',
  canViewAdviserDashboard: 'canViewAdviserDashboard',
  canViewAdviserList: 'canViewAdviserList',
  canViewClientList: 'canViewClientList',
  canViewMemberAccountComponents: 'canViewMemberAccountComponents',
  canEditAdviserDetails: 'canEditAdviserDetails',
  canViewDocuments: 'canViewDocuments',
}

@Injectable()
export class FeatureToggleService extends BaseService {

  featureTogglePermission = [
    { name: FeatureToggleName.canViewBrokerDashboard, allowed: ['group'] },
    { name: FeatureToggleName.canViewAdviserDashboard, allowed: ['adviser'] },
    { name: FeatureToggleName.canViewAdviserList, allowed: ['group'] },
    { name: FeatureToggleName.canViewClientList, allowed: ['adviser'] },
    { name: FeatureToggleName.canViewMemberAccountComponents, allowed: [] },
    { name: FeatureToggleName.canEditAdviserDetails, allowed: [] },
    { name: FeatureToggleName.canViewDocuments, allowed: ['group'] },
  ]

  constructor(
    public appstore: Store<AdvisorPortalSharedState>,
    public toastr: ToastrService,
    private sessionStorageService: SessionStorageService
  ) {
    super(toastr);
  }

  checkFeatureToggles(values: string[]): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.sessionStorageService.storageChanges.subscribe(storage => {
        var selected = storage['selected'];

        if (!selected) {
          obs.next(false);
        }
        var allowed = false;
        values.forEach(value => {
          var feature = this.featureTogglePermission.filter(x => x.name == value);
          if (feature.length > 0) {
            var selectedFeature = feature[0];
            if (selectedFeature.allowed.indexOf(selected) >= 0)
              allowed = true;
          }
        });
        obs.next(allowed);
      })
    })
  }

  checkFeatureToggle(value: string): Observable<boolean> {
    return new Observable<boolean>(obs => {

      this.sessionStorageService.storageChanges.subscribe(storage => {
        var selected = storage['selected'];

        if (!selected) {
          obs.next(false);
          return;
        }
        var feature = this.featureTogglePermission.filter(x => x.name == value);
        if (feature.length > 0) {
          var selectedFeature = feature[0];
          if (selectedFeature.allowed.indexOf(selected) >= 0) {
            obs.next(true);
            return;
          }
        }
        obs.next(false);
        return;
      })
    })
  }

}
