import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})

export class LoginErrorPageComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>,
    private msalService: MsalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    localStorage.removeItem('login-error');
  }

  onSubmitClick() {
    this.msalService.logoutRedirect();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
