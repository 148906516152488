import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent extends ComponentBase implements OnInit, OnDestroy {

  roles: string[] = [];
  helper = new Helper();


  @ViewChild('dropdown', { static: true }) dropdown: ElementRef;
  selected: string = null;

  constructor(
    public store: Store<AppState>,
    private authService: MsalService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sessionStorageService.storageChanges.subscribe(storage => {
      // get selected role
      var value = storage['selected'];
      if (value) {
        this.selected = value;
      }

      // get list of roles
      var roles = storage['roles'];
      if (roles) {
        this.roles = roles;
      }
    })
  }

  getTitle(value: string) {
    if (value == 'group')
      return 'Broker';
    return value;
  }

  select(value: string) {
    this.sessionStorageService.setItem('selected', value);
  }

  onChangeView(value: string) {
    this.sessionStorageService.setItem('selected', '');
    this.router.navigate(['dashboard']);
    this.dropdown.nativeElement.checked = false;

    setTimeout(() => {
      this.sessionStorageService.setItem('selected', value);
    }, 300);
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
