import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/app.states';
import { SystemConfigurationRequestAction } from './store/common/common.actions';
import { commonState_SystemConfig_SiteError, commomState_SystemConfig_Config } from './store/common/common.selectors';
import { TenantService } from '@ifaa-components/ui-components';
import { SessionStorageService } from './services/session-storage.service';

declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  private readonly _destroying$ = new Subject<void>();
  hasError = false;
  redirectToDashboard = false;
  siteError$ = this.store.pipe(select(commonState_SystemConfig_SiteError));
  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');



  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    public store: Store<AppState>,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(DOCUMENT) private doc: any,
    public translate: TranslateService,
    private router: Router,
    public tenantService: TenantService,
    private sessionStorageService: SessionStorageService) {
    translate.addLangs(['en', 'en.' + environment.code]);
    translate.setDefaultLang('en');
    translate.use('en.' + environment.code);
  }

  onActivate(event: any) {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 800);
  }

  async ngOnInit(): Promise<void> {
    this.authService.initialize().subscribe(x => {
      this.checkAndSetActiveAccount();
      this.setupGoogleTagManager();

      this.siteError$
        .pipe(takeUntil(this._destroying$))
        .subscribe(async x => {
          if (x) {
            await this.router.navigate(['/error'])
          }
        });

      this.tenantConfig$
        .pipe(takeUntil(this._destroying$))
        .subscribe(x => {
          if (!x) {
            this.store.dispatch(SystemConfigurationRequestAction());
          }
          else {
            this.favIcon.href = x.favIcon;
            this.tenantService.tenantEmail = x.email;
            this.tenantService.tenantPhone = x.phoneNumber;
          }
        });

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          if ((result.error as any)?.errorCode == "popup_window_error") {
            localStorage.setItem('login-error-popup-block', 'true');
            alert('Please enable popups to continue with authentication');
            this.hasError = true;
            return;
          }

          if (result.eventType === EventType.LOGIN_FAILURE) {

            if ((result.error as any)?.errorCode == 'no_cached_authority_error') {
              location.reload();
            }
            if (result.eventType === EventType.LOGIN_FAILURE) {
              // code for when user is inactive
              if (result.error.message.indexOf('server_error: AADB2C:') == -1)
                return;
            }

            this.hasError = true;
          }
        });

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          if (this.hasError) {
            console.warn('EventType Login Failure - ' + result.error);
            localStorage.setItem('login-error', 'Unauthorized');
            this.router.navigate(['/account-error'])
          }
          this.hasError = false;
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.checkAndSetActiveAccount();
        })

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
            || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe(async (result: EventMessage) => {
          this.checkAndSetActiveAccount();
          let accounts = this.authService.instance.getAllAccounts();
          if (accounts.length > 0) {

            localStorage.removeItem('login-error-popup-block')
            if (this.sessionStorageService.getItem("login-type") === 'Elevated' && !this.redirectToDashboard) {
              this.redirectToDashboard = true;
              await this.router.navigate(['dashboard']);
            }

            var account = accounts[0];

            // no token - logout
            if (!account.idTokenClaims?.extension_PlatformUserId) {
              this.authService.logoutRedirect();
            }
            else {
              // get the roles in a string array
              var roles = Object.keys(JSON.parse(account.idTokenClaims?.extension_PlatformUserId as string));

              // logout if no roles in array
              if (roles.length === 0) {
                this.authService.logoutRedirect();
              }
              // set the selected item in session storage if not set
              else if (!this.sessionStorageService.getItem('selected')) {
                this.sessionStorageService.setItem('selected', roles[0]);
              }

              // set the roles in session storage if not set
              if (!this.sessionStorageService.getItem('roles')) {
                this.sessionStorageService.setItem('roles', roles)
              }
            }
          }
        });
    });

  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setupGoogleTagManager() {
    if (!environment.ga) return;

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$
      .pipe(
        takeUntil(this._destroying$))
      .subscribe((e: NavigationEnd) => {
        gtag('config', environment.ga, { 'page_path': e.urlAfterRedirects });
      });

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.ga;
    document.head.prepend(script);

    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = ` window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.ga}');`;

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
}
