<div id="maintenance-container" class="d-flex" screen-width>
    <div class="d-flex w-100 content-wrapper" appanimatecss="{{'LOGIN_VIEW.ANIMATE_CONTENT_WRAPPER' | translate}}">
        <div class="left-side">
            <div class="left-side-content">
            </div>

        </div>
        <div class="right-side flex-fill d-flex align-items-center"
            [ngClass]="{'justify-content-center': screenWidth < 993}">
            <div class="backdrop"></div>

            <div appanimatecss="{{'LOGIN_VIEW.ANIMATE_WRAPPER' | translate}}" class="wrapper">
                <div class="alert alert-danger" *ngIf="displayPopupError">Please enable popups to continue with
                    authentication</div>

                <div class="p-3 inner-container" [ngClass]="{'m-3': screenWidth < 993}">
                    <a target="_blank" href="{{'website' | tenantconfig | async }}">
                        <div class="logo"></div>
                    </a>

                    <div class="page-title">

                    </div>
                    <div class=" d-flex flex-row w-100 ">

                        <div class="flex-fill d-flex flex-column w-100">

                            <h1 class="card-title mb-1 ">Looks like something went wrong loading the adviser portal,
                                please try again..</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>