<div class="selector-widget ">

  <div class="sec-center {{roles.length > 1? '': 'd-none'}}">
    <input #dropdown class="dropdown" type="checkbox" id="dropdown" name="dropdown" />
    <label class="for-dropdown d-flex flex-row flex-fill selected" for="dropdown">
      <div class="icon-container d-flex align-items-center">
        <mat-icon *ngIf="selected == ''">hourglass_empty</mat-icon>
        <mat-icon *ngIf="selected == 'adviser'">person</mat-icon>
        <mat-icon *ngIf="selected == 'group'">business</mat-icon>
        <mat-icon *ngIf="selected == 'group contact'">perm_contact_calendar</mat-icon>
      </div>
      <div class="flex-fill d-flex flex-column">
        <div class="viewsas">View as </div>
        <div class="selection">
          {{getTitle(selected)}}
          <div *ngIf="!selected">loading...</div>
        </div>
      </div>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </label>
    <div class="section-dropdown">
      <div class="item" (click)="onChangeView(item)" *ngFor="let item of roles">
        <div class="d-flex flex-row flex-fill align-items-center">
          <div class="icon-container d-flex align-items-center">
            <mat-icon *ngIf="item == 'adviser'">person</mat-icon>
            <mat-icon *ngIf="item == 'group'">business</mat-icon>
            <mat-icon *ngIf="item == 'group contact'">perm_contact_calendar</mat-icon>
          </div>

          <div class="flex-fill section-name">{{getTitle(item)}}</div>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>

    </div>
  </div>
</div>