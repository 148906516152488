import { AdvisorPortalSharedState } from './../modules/shared/store/shared.states';
import { Observable, Subject, Subscription, combineLatest, debounceTime, takeUntil } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { ActivatedRoute } from '@angular/router';
import { AppInjector } from '../app.module';
import { commomState_ScreenWidth } from '../store/common/common.selectors';
import { Injectable } from '@angular/core';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class CommomService {
  constructor(public store: Store<AppState>) {
  }
}

export class ComponentBase {
  public screenWidth: number = 0;
  public destroy$ = new Subject();
  advisorPortalstore = AppInjector.get(Store<AdvisorPortalSharedState>);
  commomService = AppInjector.get(CommomService);
  sessionStorageService = AppInjector.get(SessionStorageService)

  public set sub(value: Subscription) {
    this.addSubscription(value);
  }
  private subscriptions: Subscription[] = [];

  constructor() {
    // window.scrollTo(0, 0);

    this.commomService.store.pipe(
      takeUntil(this.destroy$),
      select(commomState_ScreenWidth)).subscribe(x => {
        this.screenWidth = x;
      });
  }

  dispatch(store: Store<any>, action: Action) {
    setTimeout(() => store.dispatch(action), 0);
  }


  selectedChanged(callback: any) {
    this.sub = this.sessionStorageService.storageChanges
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(storage => {
        var selected = storage['selected'];
        if (selected)
          callback();
      });
  }



  onSelectedChanged(selector: Observable<any>, callback: any, debouncetime: number = 0) {
    this.sub = combineLatest([this.sessionStorageService.storageChanges, selector])
      .pipe(
        debounceTime(debouncetime),
        takeUntil(this.destroy$))
      .subscribe(([storage, selector]) => {
        var selected = storage['selected'];
        if (selected) {
          callback(selector);
        }
      });
  }

  screenWidthChange(store: Store<AppState>, callback: any) {
    this.sub = store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
      this.screenWidth = x;
      callback(x);
    });

  }
  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  ngOnInitBase() {
  }

  getRouteParam(route: ActivatedRoute, name: string, callback: any, callbackNotFound: any) {
    const sub = route
      .params
      .subscribe(params => {
        const val = params[name];
        if (val) {
          callback(val);
        }
        else {
          if (callbackNotFound)
            callbackNotFound()
        }
      });
    this.addSubscription(sub);
  }

  ngOnDestroyBase() {
    // this.destroy$.next();
    this.destroy$.complete();

    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    });
  }

  getUrlParameterByName(name: string, url?: any) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
