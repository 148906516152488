import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { commomState_LoggedinInfo, commonState_SystemConfig_SiteError } from '../store/common/common.selectors';
import { IsLoggedInRequestAction } from '../store/common/common.actions';
import { distinctUntilChanged, map, skipWhile, tap } from 'rxjs/operators';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class AccountDataGuardService implements CanActivate {

    siteError$ = this.store.pipe(select(commonState_SystemConfig_SiteError));
    isLoggedIn$ = this.store.pipe(select(commomState_LoggedinInfo));

    constructor(public store: Store<AppState>, private sessionStorageService: SessionStorageService) {
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return combineLatest([this.siteError$, this.isLoggedIn$, this.sessionStorageService.storageChanges])
            .pipe(
                distinctUntilChanged((x, y) => {
                    if (
                        JSON.stringify(x[0]) !== JSON.stringify(y[0]) ||
                        JSON.stringify(x[1]) !== JSON.stringify(y[1]) ||
                        JSON.stringify(x[2]) !== JSON.stringify(y[2]))
                        return false;

                    return true;
                }),
                tap(x => {
                    // If its a site wide error, dont retry any api calls (avoid infinite loop)
                    if (x[0]) {
                        return;
                    }
                    if (!x[1] && (x[2])) {
                        this.store.dispatch(IsLoggedInRequestAction());
                    }
                }),
                skipWhile(x => {
                    if (!x[1] || (!x[2]))
                        return true;

                    return false;
                }),
                map(x => {
                    // If its a site wide error, return false
                    if (x[0] || !x[1])
                        return false;
                    return true;
                })
            );
    }
}
