import { createSelector } from '@ngrx/store';
import { AppState } from '../app.states';
import { ICommonState } from './common.state';

export const getCommon = (state: AppState) => state.commonState;

export const commomState_MenuList = createSelector(
  getCommon,
  (state: ICommonState) => state.menuList
);

export const commomState_IsLoading = createSelector(
  getCommon,
  (state: ICommonState) => state.isLoading
);

export const commomState_ScreenWidth = createSelector(
  getCommon,
  (state: ICommonState) => state.screenWidth
);


export const commomState_SystemConfig_FeatureToggle = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.featureToggle
);
export const commomState_SystemConfig_Messages = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.messages
);

export const commomState_SystemConfig_Config = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.tenantConfig
);

export const commomState_TenantCode = createSelector(
  getCommon,
  (state: ICommonState) => state.tenantCode
);

export const commomState_LoggedinInfo = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel
);

export const commomState_AllRoles = createSelector(
  getCommon,
  (state: ICommonState) => state.loggedInModel.app?.roles
);

export const commomState_AllPermissions = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.permissions
);

export const commomState_Env = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.env
);

export const commomState_Version = createSelector(
  getCommon,
  (state: ICommonState) => state.systemConfig.version
);

export const commonState_SystemConfig_SiteError = createSelector(
  getCommon,
  (state: ICommonState) => {
    return state.siteError
  }
)
