import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { Title } from "@angular/platform-browser";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'login-master',
  templateUrl: './not-loggedin.master.html',
  styleUrls: ['./not-loggedin.master.scss']
})

export class NotLoggedInMasterComponent extends ComponentBase {

  constructor(public store: Store<UIComponentState>,
    private titleService: Title,
    public dialog: MatDialog,
    private meta: Meta,) {
    super();
    this.titleService.setTitle("Smart Monday – Member Portal");
    this.meta.addTag({ name: 'description', content: 'Smart Monday Member Portal. Log in to access your super account details, super balance and change your details.' });
    this.meta.addTag({ name: 'keywords', content: 'Smart Monday, Smart Monday Member Portal, Smart Monday Login' });
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
